<template>
  <v-main style="overflow: auto">
    <div v-if="loadingTemplate" class="no-template">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!loadingTemplate">
      <div class="content-wrapper pa-6">
        <h2 class="mb-6">{{ $t('global.editBaseProduct') }}</h2>
        <v-form>
          <div class="form-wrapper">
            <div class="form-input-wrapper">
              <div class="form-input-first-column">
                <!-- Name Input Field -->
                <v-text-field
                  v-model="form.name"
                  :error-messages="nameErrors"
                  :label="$t('global.title')"
                  required
                  @input="$v.form.name.$touch()"
                  @blur="$v.form.name.$touch()"
                  filled
                ></v-text-field>

                <!-- Thing Type Family Selection -->
                <v-select
                  v-model="selectedThingTypeFamily"
                  :items="thingTypeFamilyOptions"
                  @change="onThingTypeFamilyChange"
                  :label="$t('global.thingTypeFamily')"
                  multiple
                  chips
                  :close-on-select="false"
                  attach
                  required
                  filled
                  return-object
                  @input="$v.form.thingTypes.$touch()"
                  @blur="$v.form.thingTypes.$touch()"
                ></v-select>
              </div>
              <div class="form-actions">
                <!-- Form Actions -->
                <div class="d-flex flex-wrap">
                  <v-btn :block="showBlockButtons" @click="cancel()">
                    {{ $t('global.cancel') }}
                  </v-btn>
                  <v-btn
                    :block="showBlockButtons"
                    color="primary"
                    class="ml-2 mb-2"
                    @click="updateEligibilities()"
                  >
                    {{ $t('global.save') }}
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- Feature Type Navigation -->
            <div>
              <v-tabs v-model="currentTab" show-arrows class="mt-2">
                <v-tab @click="setFeatureFilter('ALL')">
                  {{ this.$t('global.eligibilityFilter.all') }}
                </v-tab>
                <v-tab @click="setFeatureFilter('ONE_OFF')">
                  {{ this.$t('global.eligibilityFilter.oneOff') }}
                </v-tab>
                <v-tab @click="setFeatureFilter('SUBSCRIPTION')">
                  {{ this.$t('global.eligibilityFilter.subscription') }}
                </v-tab>
                <v-tab @click="setFeatureFilter('SERVICE_SEQUENCES')">
                  {{ this.$t('global.eligibilityFilter.serviceSequences') }}
                </v-tab>
                <v-tab @click="setFeatureFilter('PREMIUM')">
                  {{ this.$t('global.eligibilityFilter.premium') }}
                </v-tab>
              </v-tabs>
            </div>
            <!-- Eligibilities List -->
            <div
              v-if="form.thingTypes && form.thingTypes.length && filteredEligibilities.length"
              class="mb-8"
            >
              <!-- Header -->
              <div class="eligibility-header">
                <div class="name-header">{{ this.$t('global.feature') }}</div>
                <div class="availability-header">
                  {{ this.$t('global.availabilityBaseProduct') }}
                </div>
                <div class="value-header">{{ this.$t('global.defaultAvailability') }}</div>
              </div>

              <!-- Eligibility rows -->
              <div v-for="eligibility in filteredEligibilities" :key="eligibility.name">
                <EligibilityControl
                  :eligibility="eligibility"
                  v-model="form.eligibilities[eligibility.name]"
                  @input="updateEligibility(eligibility.name, $event)"
                />
              </div>

              <v-progress-linear
                v-if="pendingUpdate"
                indeterminate
                color="primary"
                absolute
                top
              ></v-progress-linear>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { companyThingTypes } from '@/graphql/query/companyThingTypes'
import { eligibilitiesForm } from '@/graphql/query/eligibilitiesForm'
import { eligiblityTemplateById } from '@/graphql/query/eligiblityTemplateById'
import { eligibilityTemplateUpdate } from '@/graphql/mutations/eligibilityTemplateUpdate'
import { companyWithDevices } from '@/graphql/query/companyWithDevices'
import EligibilityControl from '@/components/ProductCatalogue/EligibilitiesInputs/EligibilityControl'
import { thingTypeFamilies } from '@/graphql/query/thingTypeFamilies'

export default {
  name: 'EditBaseProductPage',
  props: {
    baseProductId: String,
  },
  mixins: [validationMixin],
  components: { EligibilityControl },
  data: () => {
    return {
      form: {
        name: '',
        thingTypes: [],
        eligibilities: {},
        setDefault: false,
      },
      isTemplate: false,
      thingTypeOptions: [],
      eligibilitiesHaveError: false,
      selectedDevice: null,
      selectedProduct: null,
      usesNewProductCatalogue: false,
      devicesByCompanyId: [],
      showEligibilities: false,
      generatedName: '',
      featureFilter: 'ALL',
      currentTab: 0,
      eligibilitiesForm: [],
      eligiblityTemplate: [],
      initialLoadComplete: false,
      loadingTemplate: true,
      pendingUpdate: false,
      selectedThingTypeFamily: [],
      thingTypeFamilyOptions: [],
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        thingTypes: { required },
      },
    }
  },
  apollo: {
    thingTypeFamilies: {
      query: thingTypeFamilies,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      result({ data }) {
        this.thingTypeFamilyOptions = data.thingTypeFamilies.map((family) => ({
          text: family.name,
          value: family.id,
        }))
      },
    },
    devicesByCompanyId: {
      query: companyWithDevices,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      update(data) {
        this.usesNewProductCatalogue = data.company.usesNewProductCatalogue
        return data.company.devices.edges.map((deviceEdge) => {
          return {
            name: deviceEdge.node.name,
            id: deviceEdge.node.id,
            thingType: deviceEdge.node.thingType,
          }
        })
      },
    },
    thingTypes: {
      query: companyThingTypes,
      variables() {
        return {
          companyId: this.currentCompanyId,
        }
      },
      update(data) {
        return data.company.thingTypes
      },
    },
    eligibilitiesForm: {
      query: eligibilitiesForm,
      variables() {
        return {
          companyId: this.currentCompanyId,
          thingTypes: this.form.thingTypes,
          lang: this.$i18n.locale,
        }
      },
      skip() {
        return this.form.thingTypes.length === 0
      },
      fetchPolicy: 'network-only',
      result(response) {
        if (!this.initialLoadComplete && !this.loadingTemplate) {
          return
        }
        this.eligibilitiesForm = response.data?.eligibilitiesForm || []
      },
    },
    eligiblityTemplate: {
      query: eligiblityTemplateById,
      variables() {
        return {
          templateId: this.baseProductId,
          lang: this.$i18n.locale,
        }
      },
      loadingKey: 'loadingTemplate',
      fetchPolicy: 'network-only',
      result(response) {
        const templateData = response.data.eligibilityTemplate
        if (templateData && !Array.isArray(templateData.eligibilities)) {
          const eligibilitiesArray = Object.keys(templateData.eligibilities).map((key) => {
            return {
              ...templateData.eligibilities[key],
              name: key,
            }
          })
          this.eligiblityTemplate = eligibilitiesArray
        } else {
          this.eligiblityTemplate = templateData.eligibilities
        }

        this.form.name = templateData.name
        this.form.thingTypes = templateData.thingTypes.map((thingType) => thingType.name)

        // Ensure thingTypes and thingTypeFamilyOptions are loaded
        this.$nextTick(() => {
          this.setThingTypeFamilyBasedOnTemplate()
        })

        this.loadingTemplate = false
        this.initialLoadComplete = true
      },
    },
  },

  methods: {
    updateEligibility(eligibilityName, updatedValue) {
      // Check if the eligibility exists in the form data and add a changed prop
      const existingEligibility = this.form.eligibilities[eligibilityName]

      if (existingEligibility) {
        this.$set(this.form.eligibilities, eligibilityName, {
          ...updatedValue,
          wasChanged: true,
        })
      } else {
        this.$set(this.form.eligibilities, eligibilityName, {
          ...updatedValue,
          wasChanged: true,
        })
      }
    },
    onThingTypeFamilyChange(selectedFamilies) {
      if (this.selectedThingTypeFamily.length === 0) {
        this.form.thingTypes = []
        return
      }
      this.selectedThingTypeFamily = selectedFamilies
      this.fetchThingTypesForSelectedFamilies()
    },

    fetchThingTypesForSelectedFamilies() {
      if (this.selectedThingTypeFamily.length === 0) {
        console.error('No ThingTypeFamily selected')
        return
      }

      const filteredThingTypes = this.thingTypes.filter((tt) =>
        this.selectedThingTypeFamily.some((family) => tt.thingTypeFamilyId === family.value),
      )

      this.form.thingTypes = filteredThingTypes.map((tt) => tt.name)

      // Fetch eligibilities based on the newly set ThingTypes
      this.fetchEligibilitiesForThingTypes(this.form.thingTypes)
    },

    setThingTypeFamilyBasedOnTemplate() {
      // Wait for the necessary data to be loaded
      if (!this.thingTypes?.length || !this.thingTypeFamilyOptions?.length) return

      const familyIds = this.thingTypes
        .filter((tt) => this.form.thingTypes.includes(tt.name))
        .map((tt) => tt.thingTypeFamilyId)

      const selectedFamilies = this.thingTypeFamilyOptions.filter((family) =>
        familyIds.includes(family.value),
      )

      if (selectedFamilies.length) {
        this.selectedThingTypeFamily = selectedFamilies
      }
    },

    resetForm() {
      this.form = {
        name: '',
        thingTypes: [],
        eligibilities: {},
        setDefault: false,
      }
      this.selectedDevice = null
      this.selectedProduct = null
      this.showEligibilities = false
    },
    setFeatureFilter(filterType) {
      this.featureFilter = filterType
    },
    async updateEligibilities() {
      this.$v.$touch()
      if (!this.$v.$invalid && !this.eligibilitiesHaveError) {
        this.pendingUpdate = true
        try {
          // Map through eligibilitiesForm to get additional properties
          const additionalProps = this.eligibilitiesForm.reduce((acc, cur) => {
            acc[cur.name] = {
              provisionType: cur.provisionType,
              serviceSequence: cur.serviceSequence,
            }
            return acc
          }, {})

          // Prepare eligibilities by merging additional properties
          const eligibilitiesWithAdditionalProps = Object.entries(this.form.eligibilities).reduce(
            (acc, [key, value]) => {
              acc[key] = {
                ...value,
                ...additionalProps[key],
              }
              return acc
            },
            {},
          )

          // Prepare variables for eligibilityTemplateUpdate mutation
          const eligibilityTemplateInput = {
            name: this.form.name,
            templateId: this.baseProductId,
            companyId: this.currentCompanyId,
            eligibilities: eligibilitiesWithAdditionalProps,
            thingTypes: this.form.thingTypes,
          }

          const eligibilityResponse = await this.$apollo.mutate({
            mutation: eligibilityTemplateUpdate,
            variables: {
              input: eligibilityTemplateInput,
            },
          })

          if (eligibilityResponse.data.eligibilityTemplateUpdate.success) {
            this.$toast.success(this.$t('global.newProductCreate.success'))
            this.$store.commit('setHasUnsavedChanges', false)
            this.$router.push({ name: 'NewProductCataloguePage' })
          } else {
            this.$toast.error(this.$t('global.newProductCreate.failure'))
            throw new Error('Eligibility Template Creation Failed')
          }
        } catch (error) {
          console.error(error)
          this.$toast.error(this.$t('global.newProductCreate.failure'))
        } finally {
          this.pendingUpdate = false
        }
      }
    },
    async fetchEligibilitiesForThingTypes(thingTypeNames) {
      if (thingTypeNames.length === 0 || !this.initialLoadComplete) return

      if (!this.$apollo.queries.eligibilitiesForm.variables) {
        this.$apollo.queries.eligibilitiesForm.variables = {}
      }

      await this.$apollo.queries.eligibilitiesForm.refetch({
        thingTypes: thingTypeNames,
        companyId: this.currentCompanyId,
        lang: this.$i18n.locale,
      })
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges) {
        return this.$t('global.unsavedChanges')
      }
    },
    cancel() {
      this.$store.commit('setHasUnsavedChanges', false)
      this.$router.back()
    },
  },
  watch: {
    'thingTypes.length'() {
      this.setThingTypeFamilyBasedOnTemplate()
    },
    'thingTypeFamilyOptions.length'() {
      this.setThingTypeFamilyBasedOnTemplate()
    },
    thingTypes() {
      this.thingTypeOptions = this.thingTypes.map((tt) => ({
        text: tt.businessName || tt.name,
        value: tt.name,
      }))
    },
    eligibilitiesForm(newEligibilities) {
      this.form.eligibilities = newEligibilities.reduce((accumulator, currentEligibility) => {
        const templateEligibility = this.eligiblityTemplate.find(
          (e) => e.name === currentEligibility.name,
        )

        if (templateEligibility) {
          // If found in template, use its defaultAvailability and defaultValue
          accumulator[currentEligibility.name] = {
            defaultAvailability: templateEligibility.defaultAvailability,
            defaultValue: false,
            // defaultValue: templateEligibility.defaultValue,
          }
        } else {
          // If not found, use default logic to set values
          const defaultValue = currentEligibility.serviceSequence
            ? true
            : currentEligibility.defaultValue
          accumulator[currentEligibility.name] = {
            defaultAvailability: defaultValue ? 'available' : 'not_available',
            defaultValue: true,
          }
        }
        return accumulator
      }, {})
    },
    '$v.$anyDirty'(isDirty) {
      this.$store.commit('setHasUnsavedChanges', isDirty)
    },
    'form.thingTypes'(newThingTypes) {
      if (newThingTypes.length > 0) {
        this.fetchEligibilitiesForThingTypes(newThingTypes)
      }
    },
  },
  computed: {
    ...mapGetters('user', ['currentCompanyId']),
    ...mapGetters(['hasUnsavedChanges']),
    filteredEligibilities() {
      if (!this.eligibilitiesForm || this.eligibilitiesForm.length === 0) {
        return []
      }
      switch (this.featureFilter) {
        case 'ALL':
          return this.eligibilitiesForm
        case 'ONE_OFF':
          return this.eligibilitiesForm.filter(
            (eligibility) => eligibility.provisionType === 'ONE_OFF',
          )
        case 'SUBSCRIPTION':
          return this.eligibilitiesForm.filter(
            (eligibility) => eligibility.provisionType === 'SUBSCRIPTION',
          )
        case 'SERVICE_SEQUENCES':
          return this.eligibilitiesForm.filter((eligibility) => eligibility.serviceSequence)
        case 'PREMIUM':
          return this.eligibilitiesForm.filter((eligibility) => eligibility.isPremium)
        default:
          return this.eligibilitiesForm
      }
    },
    nameErrors() {
      const errors = []
      if (!this.$v.form.name.$dirty) {
        return errors
      }
      !this.$v.form.name.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    thingTypesErrors() {
      const errors = []
      if (!this.$v.form.thingTypes.$dirty) {
        return errors
      }
      !this.$v.form.thingTypes.required && errors.push(this.$t('global.formValidation.required'))
      return errors
    },
    showBlockButtons() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    actualProductType() {
      return this.isTemplate ? 'TEMPLATE' : this.productType
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('global.unsavedChanges'))
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload
  },
  destroyed() {
    window.onbeforeunload = undefined
    this.$store.commit('setHasUnsavedChanges', false)
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/variables';

/deep/.v-slide-group {
  background-color: tint(@color-brand-highlight, 50%) !important;
  margin-bottom: 20px;

  .v-tab {
    color: @color-brand-2 !important;
    opacity: 0.5;

    &--active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .v-tabs-slider-wrapper {
    color: @color-brand-2 !important;
  }
}

.content-wrapper {
  border-bottom: 1px solid #eeeeee;
  padding: 16px;
}

.eligibility-header {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  margin-bottom: 8px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #ececec;
}

.name-header,
.availability-header,
.value-header {
  text-align: left;
}

.name-header {
  flex: 1;
}

.availability-header {
  width: calc(24% - 16px);
}

.value-header {
  width: 8.5%;
}

.form-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-input-first-column {
  width: 25%;
}

.form-actions {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}
</style>
