export default {
  usersList: 'Seznam uživatelů',
  companyList: 'Seznam servisních firem',
  userDetail: 'Údaje uživatele',
  serviceCompanyDetail: 'Údaje servisní firmy',
  addNewUser: 'Přidat uživatele',
  addNewCompany: 'Přidat novou servisní firmu',
  userPermissions: 'Uživatelská oprávnění',
  serviceCompaniesPermissions: 'Oprávnění servisních firem',
  addUser: 'Přidat uživatele',
  addCompany: 'Přidat firmu',
  setPermissions: 'Nastavit oprávnění',
  search: 'Vyhledat',
  permissions: {
    companyAdministration: 'Administrace firmy',
    companyUserManagement: 'Správa uživatelů',
    templateManagement: 'Správa šablon',
    productCatalogue: 'Zákazníci a služby',
    otherCompaniesManagement: 'Správa ostatních firem',
    configurationUpload: 'Odeslat konfiguraci',
    configurationChanges: 'Změna konfigurace',
    expertMode: 'Expertní režim',
    deviceControl: 'Ovládání zařízení',
    operationalHistory: 'Provozní historie',
    fullCustomerData: 'Plné zákaznické údaje',
    restrictedCustomerData: 'Omezené zákaznické údaje',
    userManagement: 'Správa uživatelských přístupů',
    serviceSequences: 'Servisní sekvence',
  },
  permissionsDescription: {
    companyAdministration: 'Správa firemního účtu.',
    companyUserManagement:
      'Přístup k seznamu uživatelů, vytváření a mazání uživatelských účtů, nastavování oprávnění jiným uživatelům (kromě administrátorských účtů).',
    templateManagement: 'Přístup k firemním šablonám, vytváření, aktualizace a mazání šablon.',
    productCatalogue: 'Přístup k produktovému katalogu.',
    otherCompaniesManagement: 'Zobrazení a správa zařízení partnerských firem.',
    configurationUpload: 'Odesílání nastavení do zařízení.',
    configurationChanges: 'Vytváření nových a editace zařízení bez odeslání nastavení.',
    expertMode: 'Vstup do expertního režimu.',
    deviceControl:
      'Ovládání zařízení v ovládacím panelu, změny provozních režimů kromě expertního režimu.',
    operationalHistory:
      'Přístup k historii zařízení a technickým grafům. Umožňuje vytvářet servisní záznamy a poznámky.',
    fullCustomerData: 'Zobrazení všech kontaktních údajů',
    restrictedCustomerData: 'Zobrazení pouze země a obce kontaktu',
    userManagement:
      'Přístup k seznamu uživatelů zařízení, vytváření a odstranění přístupu k zařízení, nastavování oprávnění jiným uživatelům, přístup k nastavení upozornění',
    serviceSequences: 'Spouštění a zobrazení průběhu servisních sekvencí.',
  },
  userDeleteSuccess: 'Uživatel úspěšně odstraněn z firmy.',
  userDeleteFailure: 'Odstranění uživatele z firmy se nezdařilo.',
  serviceCompanyDeleteSuccess: 'Servisní firma úspěšně odebrána z firmy.',
  serviceCompanyDeleteFailure: 'Odebrání servisní firmy z firmy se nezdařilo.',
  userSuccessfullyAdded: 'Nový uživatel úspěšně přidán do firmy.',
  userInviteUserAlreadyInvited: 'Uživatel byl již pozván.',
  userInviteUserAlreadyAdded: 'Uživatel je již ve firmě.',
  invitationSuccess: 'Pozvánka úspěšně odeslána.',
  invitationFailure: 'Odeslání pozvánky se nezdařilo.',
  companyInviteNotAdminEmail: 'Zadaný email nepatří administrátorovi žádné firmy.',
  companyInvitationAlreadyInvited: 'Firma již byla pozvána.',
  companyInvitationAlreadyAdded: 'Firma je již pod firmou.',
  companyInvitationCanNotInviteOwnCompany: 'Nelze pozvat firmu k sobě samé.',
  userDeleteConfirmation: {
    heading: 'Odstanit uživatele z firmy',
    text: 'Opravdu chcete odstranit tohoto uživatele z firmy?',
  },
  serviceCompanyDeleteConfirmation: {
    heading: 'Odstanit servisní firmu',
    text: 'Opravdu chcete odstranit tuto servisní firmu ze své firmy?',
  },
  userNotFound: 'Uživatel nenalezen.',
  addNewUserDescription:
    'Zadejte email nového uživatele. Na e-mail bude zaslána pozvánka s aktivačním odkazem.',
  addNewCompanyDescription:
    'Zadejte email administrátora firmy, kterou chcete přidat. Na e-mail bude zaslána pozvánka s aktivačním odkazem.',
  permissionsByUser: 'Nastavit oprávnění podle stávajícího uživatele',
  permissionsByCompany: 'Nastavit oprávnění podle správcovské firmy',
  permissionsTitle: 'Oprávnění',
  setPermissionsManually: 'Nastavit oprávnění ručně',
  setPermissionsByUser: 'Nastavit práva podle existujícího uživatele',
  setPermissionsByCompany: 'Nastavit práva podle existující firmy',
  sendInvitation: 'Odeslat pozvánku',
  notEnabled: 'Uživatel doposud nepřijal pozvánku.',
  notEnabledCompany: 'Firma doposud nepřijala pozvánku.',
  noMatchingRecordsFound: 'Nenalezeny žádné odpovídající záznamy.',
  noServiceCompanies: 'Nemáte žádné servisní společnosti.',
  userAdminDeleteConfirmation: {
    heading: 'Odstanit uživatele',
    text: 'Opravdu chcete odstranit tohoto uživatele ({email})?',
  },
  userAdminDeleteSuccess: 'Uživatel úspěšně odstraněn.',
  userAdminDeleteFailure: 'Odstranění uživatele se nezdařilo.',
}
