export default {
  usersList: 'List of users',
  companyList: 'List of service companies',
  userDetail: 'User detail',
  serviceCompanyDetail: 'Service company details',
  addNewUser: 'Add User',
  addNewCompany: 'Add new service company',
  userPermissions: 'User permissions',
  serviceCompaniesPermissions: 'Service company permissions',
  addUser: 'Add user',
  addCompany: 'Add company',
  setPermissions: 'Set permissions',
  search: 'Search',
  permissions: {
    companyAdministration: 'Company administration',
    companyUserManagement: 'User management',
    templateManagement: 'Template management',
    productCatalogue: 'Customers & products',
    otherCompaniesManagement: 'Manage other companies',
    configurationUpload: 'Upload configuration',
    configurationChanges: 'Modify configuration',
    expertMode: 'Expert Mode',
    deviceControl: 'Thing control',
    operationalHistory: 'Operational history',
    fullCustomerData: 'Full customer data',
    restrictedCustomerData: 'Restricted customer data',
    userManagement: 'User access management',
    serviceSequences: 'Service sequences',
  },
  permissionsDescription: {
    companyAdministration: 'Company account management.',
    companyUserManagement:
      'Access the list of users, create and delete user accounts, set permissions for other users (except administrator accounts).',
    templateManagement: 'Access company templates, creating, updating and deleting templates.',
    productCatalogue: 'Access to product catalogue.',
    otherCompaniesManagement: "View and manage partner companies' Things.",
    configurationUpload: 'Upload configuration to the Thing.',
    configurationChanges: 'Create and edit Things without upload to the Thing.',
    expertMode: 'Access the Expert Mode.',
    deviceControl:
      'Control the Thing in control panel, change operating modes except for Expert Mode.',
    operationalHistory:
      'Access Thing history and technical charts, creating service records and notes.',
    fullCustomerData: 'Display full contact details',
    restrictedCustomerData: 'Display contact city and country only',
    userManagement:
      'Access the list of Thing users, create and delete Thing access rights, set permissions for other users, access the notification settings',
    serviceSequences: 'Launch and see progress of service sequences.',
  },
  userDeleteSuccess: 'User successfully removed from the company.',
  userDeleteFailure: 'Failed to remove user from the company.',
  serviceCompanyDeleteSuccess: 'Service company successfully removed from the company.',
  serviceCompanyDeleteFailure: 'Failed to remove service company from the company.',
  userSuccessfullyAdded: 'New user successfully added to the company.',
  userInviteUserAlreadyInvited: 'User has already been invited.',
  userInviteUserAlreadyAdded: 'The user is already in the company.',
  invitationSuccess: 'Invitation successfully sent.',
  invitationFailure: 'Failed to send invitation.',
  companyInviteNotAdminEmail: 'The email you entered does not belong to any company administrator.',
  companyInvitationAlreadyInvited: 'Company has already been invited.',
  companyInvitationAlreadyAdded: 'Company is already under the given company.',
  companyInvitationCanNotInviteOwnCompany: 'Can not invite a company to itself.',
  userDeleteConfirmation: {
    heading: 'Delete user from company',
    text: 'Are you sure you want to remove this user from the company?',
  },
  serviceCompanyDeleteConfirmation: {
    heading: 'Delete service company',
    text: 'Are you sure you want to delete this service company from your company?',
  },
  userNotFound: 'User not found.',
  addNewUserDescription:
    "Enter the new user's email. An invitation with an activation link will be sent to the email.",
  addNewCompanyDescription:
    'Enter email of the administrator of the company you want to add. An invitation with an activation link will be sent to the email.',
  permissionsByUser: 'Use permissions of an existing user',
  permissionsByCompany: 'Use permissions of supervising company',
  permissionsTitle: 'Permissions',
  setPermissionsManually: 'Set permissions manually',
  setPermissionsByUser: 'Use permissions of an existing user',
  setPermissionsByCompany: 'Use permissions of an existing company',
  sendInvitation: 'Send invitation',
  notEnabled: 'User has not accepted the invitation.',
  notEnabledCompany: 'Company has not accepted the invitation.',
  noMatchingRecordsFound: 'No matching records found.',
  noServiceCompanies: 'No service companies.',
  userAdminDeleteConfirmation: {
    heading: 'Delete user',
    text: 'Are you sure you want to delete this user ({email})?',
  },
  userAdminDeleteSuccess: 'User successfully deleted.',
  userAdminDeleteFailure: 'Failed to delete user.',
}
